import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Home from './pages/Home';
import './assets/sass/style.scss';
import { Web3ReactProvider } from "@web3-react/core";
import { ContractProvider } from './libraries/crypto/ContractContext';
import getLibrary from './libraries/crypto/getLibrary';

function App() {

  var routerBaseName = process.env.REACT_APP_ROUTER_BASE;

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <ContractProvider>
        <div className="App">
          <Router basename={routerBaseName}>
            <Routes>
              <Route path="/" element={<Home />} />
            </Routes>
          </Router>
        </div >
      </ContractProvider>
    </Web3ReactProvider>

  );
}

export default App;
import * as React from "react";
import { createContext, useEffect } from "react";
import AppContract from "./abis/custom.json";
import { injected } from "./connectors";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { useEagerConnect, useInactiveListener } from "./hooks";
import { parseBalance, formatEtherscanLink } from "./utils";
import Web3 from "web3";
import Web3Utils from "web3-utils";

import detectEthereumProvider from "@metamask/detect-provider";
import { Contract } from "web3-eth-contract";


// Contract address
const contractAddresses: any = {
    1: '0x6De27fE29eaDC38e22F91b5E13fff16C240E811d',
    4: '0xaf438d17A2a31c03396c6C99A6d12C04ad755CF6'
}

//const CONTRACT_ADDRESS = '0xaf438d17A2a31c03396c6C99A6d12C04ad755CF6';
//const CONTRACT_ADDRESS = "0x6De27fE29eaDC38e22F91b5E13fff16C240E811d";

const allowedNetworks = [1, 4];

enum ConnectorNames {
    Injected = "Injected",
}

const connectorsByName: { [connectorName in ConnectorNames]: any } = {
    [ConnectorNames.Injected]: injected,
};

export const ContractContext = createContext<any>(undefined);


export const ContractProvider = ({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element => {
    const [supply, setTotalSupply] = React.useState(0);

    const context = useWeb3React<Web3Provider>();
    const {
        connector,
        library,
        chainId,
        account,
        activate,
        deactivate,
        active,
        error,
    } = context;

    // handle logic to recognize the connector currently being activated
    const [activatingConnector, setActivatingConnector] = React.useState<any>();
    const [instance, setInstance] = React.useState<any>(null);
    const [web3Provider, setWeb3Provider] = React.useState<any>(null);
    const [accounts, setAccounts] = React.useState<string[]>([]);
    const [count, setCount] = React.useState(1);
    const [transactionHash, setTransactionHash] = React.useState("");
    const [lastTransactionLink, setLastTransactionLink] = React.useState("");
    const [loading, setLoading] = React.useState<boolean>(false);
    const [id, setID] = React.useState<number>(4);
    const [allowedNetwork, setAllowedNetwork] = React.useState<boolean>(false);
    const [totalSupplyCount, setTotalSupplyCount] = React.useState<number>(0);
    const [hasTotalSupply, setHasTotalSupply] = React.useState<boolean>(false);
    const [contractMintPrice, setContractMintPrice] = React.useState<string>('0');

    React.useEffect(() => {
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined);
        }
    }, [activatingConnector, connector]);

    React.useEffect(() => {
        const init = async () => {
            console.log('Call context refresh');

            try {
                const provider = await detectEthereumProvider();

                if (provider) {
                    const web3 = new Web3(provider as any);
                    const networkId = await web3.eth.net.getId();
                    const accounts = await web3.eth.getAccounts();

                    if (allowedNetworks.includes(networkId)) {
                        // Get the contract instance.
                        const contractAddress = contractAddresses[networkId];

                        const instance = new web3.eth.Contract(
                            // @ts-ignore
                            AppContract.abi,
                            contractAddress
                        );

                        setInstance(instance);

                        let mintPriceVal = process.env.REACT_APP_MINT_PRICE ?? '';
                        let totalSupplyVal = 0;

                        try {
                            mintPriceVal = await mintPrice(instance);
                            totalSupplyVal = await totalSupply(instance);
                        } catch (ex) {
                            alert(ex);
                            setHasTotalSupply(false);
                        }
                        setContractMintPrice(mintPriceVal);
                        setTotalSupplyCount(totalSupplyVal);
                        setAllowedNetwork(true);
                    } else {
                        setInstance(null);
                        setAllowedNetwork(false);
                        setContractMintPrice(process.env.REACT_APP_MINT_PRICE ?? '');
                        setHasTotalSupply(false);
                    }

                    setAccounts(accounts);
                    setID(Number(networkId));
                    setWeb3Provider(provider);
                }
            } catch (ex) {
                console.log(ex);
            }
        };

        init();
    }, [active, error, chainId]);


    // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
    const triedEagerConnect = useEagerConnect();

    // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
    useInactiveListener(!triedEagerConnect || !!activatingConnector);
    const currentConnector = connectorsByName["Injected"];
    const activating = currentConnector === activatingConnector;
    const connected = currentConnector === connector;
    const disabled =
        !triedEagerConnect || !!activatingConnector || connected || !!error;


    const mintPrice = async (contractInstance: Contract) => {
        try {
            setLoading(true);
            if (!contractInstance) throw new Error(`No instance`);

            const {
                mintPrice,
            } = contractInstance.methods;

            let properPrice = Number(Web3Utils.fromWei(`${await mintPrice()?.call()}`)).toFixed(3);

            setLoading(false);

            return properPrice;

        } catch (ex) {
            throw ex;
        }
    }

    const totalSupply = async (contractInstance: Contract) => {
        try {
            setLoading(true);
            if (!contractInstance) throw new Error(`No instance`);

            const {
                totalSupply,
            } = contractInstance.methods;

            let count = await totalSupply()?.call();

            setLoading(false);
            setHasTotalSupply(true);

            return count;
        } catch (ex) {
            setHasTotalSupply(false);
            throw ex;
        }
    }

    const mint = async (count: number) => {
        try {
            setLoading(true);
            if (!instance) throw new Error(`No instance`);
            if (!account)
                throw new Error(`No account selected. Try reauthenticating`);
            if (!count) throw new Error(`No token count provided.`);
            // const provider = await detectEthereumProvider();

            // Get neccessary methods from contract
            const {
                isActive,
                isAllowListActive,
                presalePrice,
                mintPrice,
                mint,
                preSaleMint,
            } = instance.methods;

            const isPublicSaleActive = await isActive()?.call();

            if (!isPublicSaleActive) throw new Error(`Sales has not start yet`);

            let properPrice = Web3Utils.fromWei(`${await mintPrice()?.call()}`);
            console.log(properPrice);
            return await mint(account, count)
                .send({
                    from: account,
                    value: Web3Utils.toWei(
                        (Number(properPrice) * count).toFixed(3),
                        "ether"
                    ),
                    gasLimit: (80000 + 150000 * count).toString(),
                })
                .once("error", (err: any) => {
                    alert(`There was an issue minting the NFT - ${err.message}`);
                })
                .then((success: any) => {
                    if (success?.status) {
                        const { transactionHash } = success;
                        setTransactionHash(transactionHash);
                        const lastTransactionLink = formatEtherscanLink('Transaction', [id, transactionHash]);

                        console.log(lastTransactionLink);
                        setLastTransactionLink(lastTransactionLink);
                        setLoading(false);
                    }
                });

        } catch (error: any) {
            console.log(error);
            if( error?.message ) {
                alert(error.message);
            }
            
            setLoading(false);
        }
    };

    const switchNetwork = async (newChain: number) => {
        const hexCode = Web3Utils.toHex(newChain);

        await web3Provider?.sendAsync({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: hexCode }], // chainId must be in hexadecimal numbers
        });
    }

    const connect = async () => {
        try {
            setActivatingConnector(currentConnector);
            activate(connectorsByName["Injected"]);
        } catch (error) {
            console.log(error, "Error");
        }
    };

    function resetMint() {
        setTransactionHash("");
        setLastTransactionLink('');
    }

    return (
        <ContractContext.Provider
            value={{
                connect,
                mint,
                mintPrice,
                contractMintPrice,
                totalSupplyCount,
                hasTotalSupply,
                active,
                count,
                chainId,
                loading,
                transactionHash,
                lastTransactionLink,
                account,
                connector,
                resetMint,
                connected,
                activating,
                disabled,
                allowedNetwork,
                switchNetwork
            }}
        >
            {children}
        </ContractContext.Provider>
    );
};

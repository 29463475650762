import React from 'react';
import Landing from '../components/Landing';

const Home = () => {

    return <div>
        <Landing />
    </div>;
}

export default Home;
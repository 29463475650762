import React from "react";
import logo from '../assets/images/suw-clogo@2x.png';
import logoText from '../assets/images/suwctext.png';
import twitterIcon from '../assets/images/twitter@2x.png';
import instagramIcon from '../assets/images/instagram@2x.png';
import discordIcon from '../assets/images/discord@2x.png';

const Landing = () => {
    return (
      <section id="landing" className="landing-page">
        <p className="mb-5">COMING SOON</p>
        <img src={ logo } className="logo" alt="Swiss Unique Watch Club - Coming soon logo" />
        <h1><img src={logoText} className="logo-text" alt="Swiss Unique Watch Club"/></h1>
        <div className="socials">
          <a href="https://twitter.com/suwclub" target="_blank"><img src={twitterIcon} /></a>
          <a href="https://www.instagram.com/swissuniquewatchclub" target="_blank"><img src={instagramIcon} /></a>
          
        </div>
      </section>
    )
}

export default Landing;